import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import PlanoOdontoForm from './components/PlanoOdontoForm/_index'

import Header from './sections/header/_index'
import ComoFunciona from './sections/como-funciona/_index'
import Coberturas from './sections/coberturas/_index'
import ComoContratar from './sections/como-contratar/_index'
import Blog from './sections/blog/_index'
import Faq from './sections/faq/_index'

import useDomReady from 'src/hooks/window/useDomReady'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import pageContext from './pageContext.json'

const ProtecaoOdonto = () => {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Plano Odontológico com 2 meses sem custos',
    element_action: 'click button',
    element_name: 'Conhecer o Plano Odonto',
  })

  const OptionsPlanoOdonto = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <PlanoOdontoForm closeModal={() => setOpenModal(false)} closeFirstModal={setOpenModal} dataLayer={dataLayer} />
    </Modal>
  )
  return (
    <Layout pageContext={pageContext}>
      {OptionsPlanoOdonto}
      <Header setIsModalOpen={setOpenModal} setDataLayer={setDataLayer} />
      <ComoFunciona />
      <Coberturas setIsModalOpen={setOpenModal} setDataLayer={setDataLayer} />
      <ComoContratar setIsModalOpen={setOpenModal} setDataLayer={setDataLayer} />
      <Blog />
      <Faq />
    </Layout>
  )
}

export default ProtecaoOdonto
