import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  .btn {
     width: 312px;
     height: 48px;
     @media ${device.tablet} {
       width: 327px;
     }
     @media ${device.desktopLG} {
       width: 375px;
     }
     @media ${device.desktopXL} {
       width: 362px;
     }
  }
`
