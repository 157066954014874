import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'

import ComoFuncionaDesktopLG from '../../assets/images/como-funciona-desktopLG.png'
import ComoFuncionaDesktopXL from '../../assets/images/como-funciona-desktopXL.png'

export const Section = styled.section`
   background: ${grayscale[100]};

  .bg-como-funciona {
    @media ${device.desktopLG} {
     background: url(${ComoFuncionaDesktopLG}) ${grayscale[100]} no-repeat center;
     background-size: 455px;
   }
  
   @media ${device.desktopXL} {
     background: url(${ComoFuncionaDesktopXL}) ${grayscale[100]} no-repeat center;
   }
  }

  .react-multi-carousel-dot--active {
    button{
      background-color: ${orange.extra};
    }
  }


  .btn {
     width: 312px;
     height: 48px;
     @media ${device.tablet} {
       width: 336px;
     }
     @media ${device.desktopLG} {
       width: 374px;
     }
     @media ${device.desktopXL} {
       width: 360px;
     }
  }
`
export const Card = styled.div`
  background: white;
  padding: 16px 16px;
  border-radius: 8px;
  height: 187px;

  @media ${device.tablet} {
    height: 232px;
  }
  @media ${device.desktopLG} {
    height: 254px;
  }
`
