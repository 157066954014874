import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const BlogCarousel = styled.div`

  .primary-tag, .secundary-tag {
    display: inline;
    @media ${device.tablet} { 
      font-size: 7px;
    }
    @media ${device.desktopLG} { 
      font-size: 12px;
    }
  }

  .content {
    border-radius: 16px 16px 0 0;
    width: 100%;
    top: 22px;
    
    @media ${device.tablet} { 
      top: 31px;
      height: 152px;
    }
  }

  &.p-card {
    padding: 0;
  
    @media ${device.tablet} { 
      padding-right: 24px;
    }
    @media ${device.desktopLG} { 
      padding-right: 10.5px;
      padding-left: 10.5px
    }     
  }

  article {
    display: grid;
    min-height: 350px;

    @media ${device.desktopLG} { 
      min-height: 470px;
    }
    
    @media ${device.desktopLG} { 
      .title {
        height: 77px;
      }
    }
  }

  .title-post {
    font-family: 'Sora';
    @media ${device.tablet} { 
      letter-spacing: 0;
    }
    @media ${device.desktopLG} { 
      letter-spacing: 1;
    }
  }
`
export const Button = styled.div`
  .btn {
    width: 312px;
    height: 48px;

    @media ${device.tablet} { 
      width: 296px;
    }

    @media ${device.desktopXL} { 
      width: 356px;
    }
  }

  .space {
    @media ${device.desktopXXXL} { 
      margin-top: 40px;
    }
  }
`
