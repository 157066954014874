import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  .btn {
     width: 312px;
     height: 48px;
     
     @media ${device.tablet} { 
      width: 296px;
    }

    @media ${device.desktopXL} { 
      width: 356px;
    }
  }

  .react-multi-carousel-dot--active {
    button{
      background-color: ${orange.extra};
    }
  }
`
export const Card = styled.div`
  height: 245px;
  border: 1px solid #DEDFE4;
  border-radius: 8px;
  padding: 20px 16px;
`
export const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ff7a00;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`
