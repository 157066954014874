import React from 'react'
import Img from 'gatsby-image'

// component
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Style
import { Section } from './style'

import usePageQuery from '../../pageQuery'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type HeaderType = {
  setIsModalOpen: (state: boolean) => void;
}

interface ISeguroOdontoInter extends HeaderType {
  setDataLayer: (state: IDataLayerParams) => void;
}

const Hero = ({ setIsModalOpen, setDataLayer }: ISeguroOdontoInter) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Plano Odontológico por R$ 10,90 nos 2 primeiros meses',
    element_action: 'click button',
    element_name: 'Conhecer o Plano Odonto',
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='d-block d-md-none col-12 pb-5'>
            <Img fluid={data.familiaFelizOdonto.fluid} alt='Casal sorridente recebe abraço da filha, que também está sorrindo, enquanto posam para uma foto.' />
          </div>
          <div className='col-12 col-md-6 px-0 pr-md-3 pr-lg-5'>
            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 mb-4 text-grayscale--500 fw-600 font-sora'>
              Plano Odontológico por <span className='text-orange--extra d-xl-block'>R$ 10,90 nos 2 primeiros meses</span>
            </h1>
            <p className='fs-16 lh-20 fs-md-18 lh-md-22 mb-4 text-grayscale--400 fw-400'>
              Cuidar do sorriso ficou mais fácil com o Inter: consultas, tratamentos e exames dentários. <span className='fw-600'>Proteção para você e sua família com benefício exclusivo Inter.</span>
            </p>
            <button
              className='btn btn-orange--extra fs-14 fw-600 rounded-2 d-flex justify-content-between text-white text-none' onClick={() => {
                  setIsModalOpen(true)
                  sendDatalayerEvent(dataLayer)
                  setDataLayer(dataLayer)
              }}
            >
              Conhecer o Plano Odonto
              <OrangeDS color='white' size='MD' icon='chevron-right' className='ml-5' />
            </button>
          </div>
          <div className='d-none d-md-block col-12 col-md-6 col-xl-5 offset-xl-1'>
            <Img fluid={data.familiaFelizOdonto.fluid} alt=' Casal sorridente recebe abraço da filha, que também está sorrindo, enquanto posam para uma foto.' />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
