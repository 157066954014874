import React from 'react'

import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import cardData from './../../assets/data/comoFunciona.json'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import LeftRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Card } from './style'

type itemsProps = {
  icon: string;
  title: string;
  description: string;
}

const ComoFunciona = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const rightArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
    >
      <ArrowRight width={40} height={40} color='orange--base' />
    </button>
  )

  const leftArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--left'
    >
      <LeftRight width={40} height={40} color='orange--base' />
    </button>
  )
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center pl-md-3'>
          <div className='col-12 col-md-6 px-0 pr-md-3'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 mb-0 mb-md-4 text-grayscale--500 fw-600 font-sora d-xl-none'>
              Por que escolher o <span className='text-orange--extra'>Plano Odonto do Inter?</span>
            </h2>
            <div className='d-md-none pt-0'>
              <DefaultCarousel
                sm={{
                items: 2,
                }}
                customLeftArrow={leftArrow}
                customRightArrow={rightArrow}
              >
                {cardData.map((item: itemsProps, key: number) => (
                  <div className='mr-2 px-0' key={key}>
                    <Card>
                      <div className='mb-4'>
                        <OrangeDS icon={item.icon} size='MD' color='#FF7A00' />
                      </div>
                      <h3 className='fs-16 lh-20 text-grayscale--500 fw-600 mb-2 font-sora'>{item.title}</h3>
                      <p className='fs-10 lh-12 text-grayscale--400 fw-400 mb-0'>{item.description}</p>
                    </Card>
                  </div>
              ))}
              </DefaultCarousel>
            </div>
            <h2 className='fs-lg-40 lh-lg-50 mb-4 text-grayscale--500 fw-600 font-sora d-none d-xl-block'>
              Por que escolher o <span className='text-orange--extra'>Plano Odonto do Inter?</span>
            </h2>
            <p className='fs-16 lh-20 fs-md-18 lh-md-22 fs-lg-24 lh-lg-30 my-4 text-grayscale--500 font-sora fw-600'>
              São mais de 36 mil opções de atendimento em todo Brasil.
            </p>
            <a
              href='https://redecredenciadainter.com.br/rede-credenciada/'
              target='_blank' rel='noreferrer'
              className='btn btn-white fs-14 fw-700 rounded-2 d-flex text-grayscale--500 justify-content-between text-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_2',
                  section_name: 'Por que escolher o Plano Odonto do Inter?',
                  element_action: 'click button',
                  element_name: 'Rede Credenciada',
                  redirect_url: 'https://redecredenciadainter.com.br/rede-credenciada/',
                 })
              }}
            >
              <div>
                <OrangeDS color='#FF7A00' size='MD' icon='location' className='mr-3' />
                Rede Credenciada
              </div>
              <OrangeDS color='#FF7A00' size='MD' icon='chevron-right' className='ml-5' />
            </a>
          </div>
          <div className='col-12 col-md-6 col-xl-5 offset-xl-1 bg-como-funciona'>
            <div className='d-none d-md-block'>
              <div className='row mb-md-4 px-lg-5'>
                {cardData.map((item: itemsProps, key: number) => (
                  <div className='mt-3 px-md-2 col-md-6' key={key}>
                    <Card>
                      <div className='mb-4'>
                        <OrangeDS icon={item.icon} size='MD' color='#FF7A00' />
                      </div>
                      <h3 className='fs-18 lh-22 text-grayscale--500 fw-600 mb-2 font-sora'>{item.title}</h3>
                      <p className='fs-12 lh-16 text-grayscale--400 fw-400 mb-0'>{item.description}</p>
                    </Card>
                  </div>
              ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoFunciona
