import React from 'react'
import Img from 'gatsby-image'

import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import cardData from './../../assets/data/beneficios.json'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import LeftRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Card } from './style'

import usePageQuery from '../../pageQuery'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type BenefitsProps = {
  title: string;
  valid: boolean;
}[]

type itemsProps = {
  image: string;
  title: string;
  value: string;
  benefits: BenefitsProps;
}

type BenefitProps = {
  title: string;
  valid: boolean;
}

type HeaderType = {
  setIsModalOpen: (state: boolean) => void;
}

interface ISeguroOdontoInter extends HeaderType {
  setDataLayer: (state: IDataLayerParams) => void;
}

const Coberturas = ({ setIsModalOpen, setDataLayer }: ISeguroOdontoInter) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'Um plano odontológico pra chamar de seu!',
    element_action: 'click button',
    element_name: 'Escolher meu plano',
  }

  const rightArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
    >
      <ArrowRight width={40} height={40} color='orange--base' />
    </button>
  )

  const leftArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--left'
    >
      <LeftRight width={40} height={40} color='orange--base' />
    </button>
  )
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-5 px-0 pr-md-3 pr-lg-0'>
            <div className='d-none d-xl-block pb-xl-4'>
              <Img fluid={data.coberturasPlanoOdonto.fluid} alt='Mulher sorri enquanto contrata o Plano odontológico no Super App do Inter.' />
            </div>
            <div className='col-md-8 col-lg-12 pl-lg-3'>
              <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 mb-4 text-grayscale--500 fw-600 font-sora'>
                Um plano odontológico pra chamar de seu!
              </h2>
              <p className='fs-16 lh-20 fs-md-18 lh-md-22 mb-0 mb-lg-5 fw-400'>
                Escolha seu plano com as coberturas que mais fazem sentido pra você e sua família.
              </p>
            </div>
            <div className='d-lg-none col-12 pb-4 pb-md-0 pl-md-2 pr-md-0'>
              <DefaultCarousel
                sm={{ items: 1, partialVisibilityGutter: 40 }}
                md={{ items: 2 }}
                customLeftArrow={leftArrow}
                customRightArrow={rightArrow}
              >
                {cardData.map((item: itemsProps, index: number) => (
                  <div className='mr-2 px-0' key={index}>
                    <Card>
                      <div className='line-decoret mb-4'>
                        <div className='row mt-2 mb-2'>
                          <Img fluid={data[item.image].fluid} />
                          <div className='ml-3 mt-3'>
                            <h3 className='fs-16 lh-20 mb-0 fw-700 font-sora'>{item.title}</h3>
                            <p className='fs-14  lh-17 fw-700'>{item.value}</p>
                          </div>
                        </div>
                      </div>
                      { item.benefits.map((item: BenefitProps, index: number) => (
                        <div key={index}>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='col-1'>
                              <OrangeDS icon={item.valid ? 'check' : 'exit'} size='MD' color={item.valid ? '#00AA4F' : '#F56A50'} />
                            </div>
                            <div className='col-11 ml-3'>
                              <p className={`${item.valid ? 'text-grayscale--500' : 'text-grayscale--300 text-decoret'} fs-14 lh-17 mb-0 fw-400`}>{item.title}</p>
                            </div>
                          </div>
                        </div>
                    ))}
                    </Card>
                  </div>
              ))}
              </DefaultCarousel>
            </div>
            <button
              className='btn btn-orange--extra fs-14 fw-600 rounded-2 ml-md-2 ml-lg-3 d-flex justify-content-between text-white text-none'
              onClick={() => {
                  setIsModalOpen(true)
                  sendDatalayerEvent(dataLayer)
                  setDataLayer(dataLayer)
              }}
            >
              Escolher meu plano
              <OrangeDS color='white' size='MD' icon='chevron-right' className='ml-5' />
            </button>
          </div>
          <div className='d-none d-lg-block col-12 col-lg-7'>
            <DefaultCarousel
              lg={{ items: 2 }}
              xl={{ items: 2 }}
              customLeftArrow={leftArrow}
              customRightArrow={rightArrow}
            >
              {cardData.map((item: itemsProps, index: number) => (
                <div className='mr-2 px-0' key={index}>
                  <Card>
                    <div className='line-decoret mb-4'>
                      <div className='row mt-2 mb-2'>
                        <Img fluid={data[item.image].fluid} />
                        <div className='ml-3 mt-3'>
                          <h3 className='fs-16 lh-20 mb-0 fw-700 text-grayscale--500 font-sora'>{item.title}</h3>
                          <p className='fs-14 lh-17 fw-700 text-grayscale--400'>{item.value}</p>
                        </div>
                      </div>
                    </div>
                    { item.benefits.map((item: BenefitProps, index: number) => (
                      <div key={index}>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='col-1'>
                            <OrangeDS icon={item.valid ? 'check' : 'exit'} size='MD' color={item.valid ? '#00AA4F' : '#F56A50'} />
                          </div>
                          <div className='col-11 ml-3'>
                            <p className={`${item.valid ? 'text-grayscale--500' : 'text-grayscale--300 text-decoret'} fs-14 lh-17 mb-0 fw-400`}>{item.title}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Card>
                </div>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Coberturas
