import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  .btn {
     width: 312px;
     height: 48px;
     @media ${device.tablet} {
       width: 334px;
     }
     @media ${device.desktopLG} {
       width: 376px;
     }
     @media ${device.desktopXL} {
       width: 355px;
     }
  }
  .text-decoret {
    text-decoration-line: line-through;
  }
  .line-decoret {
    border-bottom: 1px dashed #DEDFE4;
  }
  .gatsby-image-wrapper {
    margin-left: 12px;
  }
  .react-multi-carousel-dot--active {
    button{
      background-color: ${orange.extra};
    }
  }
`
export const Card = styled.div`
  border: 1px solid #DEDFE4;
  border-radius: 8px;
  padding: 16px 16px;
`
