import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      familiaFelizOdonto: imageSharp(fluid: {originalName: { regex: "/familia-feliz-odonto/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      coberturasPlanoOdonto: imageSharp(fluid: {originalName: { regex: "/coberturas-plano-odonto/" }}) {
        fluid(maxWidth: 398, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      coberturasHomem: imageSharp(fluid: {originalName: { regex: "/coberturas-homem/" }}) {
        fluid(maxWidth: 68, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      coberturasMulher: imageSharp(fluid: {originalName: { regex: "/coberturas-mulher/" }}) {
        fluid(maxWidth: 68, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blogArtiglePlanoOdontMulher: imageSharp(fluid: {originalName: { regex: "/blog-artigle-plano-odonto-mulher/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      blogArtiglePlanoOdontFamilia: imageSharp(fluid: {originalName: { regex: "/blog-artigle-plano-odonto-familia/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      blogArtiglePlanoOdontApp: imageSharp(fluid: {originalName: { regex: "/blog-artigle-plano-odonto-app/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
