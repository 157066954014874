import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import blog from './../../assets/data/blog.json'

import usePageQuery from '../../pageQuery'

import { BlogCarousel, Button } from './style'

type itemProps = {
    image: string;
    alt: string;
    primaryTag: string;
    secondaryTag: string;
    title: string;
    data: string;
    link: string;
}

const Blog = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5 bg-grayscale--100'>
      <div className='container pb-lg-0'>
        <div className='row pl-md-3'>
          <div className='col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 mb-4 px-0 px-md-4'>
            <h2 className='fs-24 fs-md-32 fs-xl-40 lh-30 lh-md-40 lh-xl-50 px-md-2 px-lg-5 text-grayscale--500 text-md-center font-sora'>
              Saiba mais sobre o Plano Odontológico do Inter
            </h2>
            <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-20 lh-lg-22 lh-xl-30 text-grayscale--500 mt-4 text-md-center'>
              Explore os conteúdos do nosso blog e fique por dentro do assunto!
            </p>
          </div>
          {
            blog.map((item: itemProps, index: number) => (
              <BlogCarousel key={index} className='col-12 col-md-4 p-card mt-md-2 mb-5 mb-md-0'>
                <a
                  href={item.link}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_5',
                      section_name: 'Saiba mais sobre o plano odontológico do Inter',
                      element_action: 'click button',
                      element_name: item.title,
                      redirect_url: item.link,
                    })
                  }}
                >
                  <article className='col-12 px-0'>
                    <Img fluid={data[item.image].fluid} alt={item.alt} />
                    <div className='col-12 pt-3 pt-md-0 px-4 px-md-3 px-lg-4 mt-n5 bg-white content'>
                      <p className='fs-12 lh-15 fs-lg-10 lh-lg-12 fw-700 text-white bg-orange--extra rounded-1 mb-1 px-1 py-1 mr-2 primary-tag'>{item.primaryTag}</p>
                      <p className='fs-12 lh-15 fs-lg-10 lh-lg-12 fw-700 rounded-1 mb-1 px-1 py-1 mr-2 text-grayscale--500 secundary-tag'>{item.secondaryTag}</p>
                      <h3 className='fs-20 lh-25 fs-md-16 lh-md-20 fw-600 mt-2 mb-2 mb-lg-0 mt-xl-3 title-post text-grayscale--500 title font-sora'>{item.title}</h3>
                      <p className='fs-12 lh-15 fs-lg-10 lh-lg-12 mb-2 fw-700 mt-2 mt-lg-0 text-grayscale--300'>{item.data}</p>
                    </div>
                  </article>
                </a>
              </BlogCarousel>
            ))
          }
          <Button className='col-12 mb-xl-4 pr-md-4 pl-md-0  d-flex justify-content-center'>
            <a
              href='https://blog.inter.co/para-simplificar-a-vida/seguros/'
              target='_blank'
              rel='noreferrer'
              className='btn btn-orange--extra fs-14 fw-600 rounded-2 ml-lg-3 space d-flex text-white text-none text-center'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_5',
                  section_name: 'Saiba mais sobre o plano odontológico do Inter',
                  element_action: 'click button',
                  element_name: 'Acessar mais conteúdos',
                  redirect_url: 'https://blog.inter.co/para-simplificar-a-vida/seguros/',
                })
              }}
            >
              Acessar mais conteúdos
            </a>
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Blog
