import React, { ReactNode } from 'react'
import { translate } from 'react-i18next'
import { WIDTH_MD, WIDTH_SM, WIDTH_XL } from 'src/utils/breakpoints'

// hooks
import useWidth from 'src/hooks/window/useWidth'

// Images
import AppStorePT from 'inter-frontend-svgs/lib/v2/social/store/app-store'
import PlayStorePT from 'inter-frontend-svgs/lib/v2/social/store/google-play'

type FooterProps = {
  t: (values: string) => ReactNode;
}

const DisplayStore = ({ t }: FooterProps) => {
  const windowWidth = useWidth(360)
  const size = windowWidth <= WIDTH_SM ? '108' : windowWidth <= WIDTH_MD ? '94' : windowWidth <= WIDTH_XL ? '94' : '112'

  return (
    <>
      <div className='d-flex justify-content-start pt-5 pt-md-4 space-store pb-md-0'>
        <div className='col-auto mb-2 mt-md-3 mt-xl-4 mr-1 pr-md-0 mr-xl-2 pl-0'>
          <a
            href='https://play.google.com/store/apps/details?id=br.com.intermedium'
            target='_blank' rel='noreferrer'
            title='Baixe no Google Play' aria-label='Google Play' data-footer='download-google-play'
          >
            <PlayStorePT width={size} height='40' />
          </a>
        </div>
        <div className='col-auto mb-2 mt-md-3 mt-xl-4 pl-0'>
          <a
            href='https://itunes.apple.com/br/app/banco-inter/id839711154'
            target='_blank' rel='noreferrer' title='Baixe na App Store' aria-label='App Store'
            data-footer='download-app-store'
          >
            <AppStorePT width={size} height='40' />
          </a>
        </div>
      </div>
    </>
  )
}

export default translate('Footer')(DisplayStore)
